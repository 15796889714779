import { createSelector } from '@ngrx/store';
import { uploadEventSelectors } from './index';
import { Product, StepItem } from '../../../core/models/app.models';

export * as uploadEventCustomSelectors from './upload-event.selectors';

export const selectProductsArray = createSelector(
  uploadEventSelectors.selectProducts,
  (products): Product[] => {
    return Object.values(products);
  },
);

export const selectStepsArray = createSelector(
  uploadEventSelectors.selectEventFormSteps,
  (formSteps): StepItem[] => {
    return Object.values(formSteps);
  },
);

export const selectCurrentStep = createSelector(
  uploadEventSelectors.selectEventFormSteps,
  (formSteps): string | null => {
    const currentStep = Object.values(formSteps).find((item) => item.active);

    if (currentStep) {
      return currentStep.stepId;
    }

    return null;
  },
);

export const selectIsAnyProductSelected = createSelector(
  selectProductsArray,
  (products): boolean =>
    products.some((product) => product.selected && product.key !== 'flyer'),
);
export const selectSelectedProducts = createSelector(
  selectProductsArray,
  (products): any[] =>
    products
      .filter((product) => product.selected)
      .map((selectedProduct) => {
        return { price: selectedProduct.id, quantity: 1 };
      }),
);

export const selectPrice = createSelector(
  uploadEventSelectors.selectProducts,
  (products): number => {
    let price = 0;
    Object.values(products).forEach((product) => {
      if (product.selected) {
        price += product.price;
      }
    });
    return price;
  },
);

export const selectIsGenreValid = createSelector(
  uploadEventSelectors.selectEventFormSteps,
  (eventFormSteps): boolean => {
    return eventFormSteps['genresTags'].state === 'invalid';
  },
);

export const selectIsFlyerValid = createSelector(
  uploadEventSelectors.selectEventFormSteps,
  (eventFormSteps): boolean => {
    return eventFormSteps['booster'].state === 'invalid';
  },
);
