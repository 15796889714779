import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { addDays, format } from 'date-fns';
import { regexPatterns } from '../constants';
import {
  EventBoostersForm,
  EventGenreTagsForm,
  EventInformationForm,
  EventTicketingForm,
} from '../models/event-form.models';
import {
  endDateNotBeforeStartDate,
  flyerValidator,
  requiredIfEnabledValidator,
  startDateNotAfterEndDate,
  validateChosenValue,
  validateDate,
  validateTextAreaRequired,
} from '../helpers/validators';

@Injectable({
  providedIn: 'root',
})
export class UploadEventFormService {
  private uploadEventFormGroups = {
    uploadEventInformationFormGroup: new FormGroup<EventInformationForm>(
      {
        name: new FormControl<string>('', [Validators.required]),
        startDate: new FormControl<string>(
          format(addDays(new Date(), 1), 'yyyy-MM-dd'),
          [Validators.required, validateDate],
        ),
        endDate: new FormControl<string>(
          format(addDays(new Date(), 2), 'yyyy-MM-dd'),
          [Validators.required, validateDate],
        ),
        startTime: new FormControl<string>('', [
          Validators.required,
          Validators.pattern(regexPatterns.time),
        ]),
        endTime: new FormControl<string>('', [
          Validators.required,
          Validators.pattern(regexPatterns.time),
        ]),
        internalLabel: new FormControl<number>(-1, [validateChosenValue]),
        location: new FormControl<number>(0, [
          Validators.required,
          validateChosenValue,
        ]),
        locationName: new FormControl<string>(''),
        locationStreet: new FormControl<string>(''),
        locationPlz: new FormControl<string>(''),
        locationProvince: new FormControl<string>(''),
        ageRestrictionWomen: new FormControl<string>('', [Validators.required]),
        ageRestrictionMen: new FormControl<string>('', [Validators.required]),
        pressText: new FormControl<string>('', [
          validateTextAreaRequired,
          Validators.maxLength(500),
        ]),
      },
      {
        validators: [endDateNotBeforeStartDate(), startDateNotAfterEndDate()],
      },
    ),
    uploadEventTicketingFormGroup: new FormGroup<EventTicketingForm>({
      fromPrice: new FormControl<string>('', [requiredIfEnabledValidator]),
      ticketLink: new FormControl<string>('', [requiredIfEnabledValidator]),
      fyraTicketLink: new FormControl<string>('', []),
    }),
    uploadEventGenresTagsFormGroup: new FormGroup<EventGenreTagsForm>({
      genres: new FormControl<number[]>([], [Validators.required]),
      tags: new FormControl<number[]>([], []),
    }),
    uploadEventBoostersFormGroup: new FormGroup<EventBoostersForm>(
      {
        boosters: new FormControl<string[]>([], []),
        flyerUploaded: new FormControl<boolean>(false),
      },
      {
        validators: [flyerValidator()],
      },
    ),
  };

  getUploadEventInformationFormGroup() {
    return this.uploadEventFormGroups['uploadEventInformationFormGroup'];
  }

  getUploadEventTicketingFormGroup() {
    return this.uploadEventFormGroups['uploadEventTicketingFormGroup'];
  }

  getUploadEventGenreTagsFormGroup() {
    return this.uploadEventFormGroups['uploadEventGenresTagsFormGroup'];
  }

  getUploadEventBoostersFormGroup() {
    return this.uploadEventFormGroups['uploadEventBoostersFormGroup'];
  }

  validateUploadForm() {
    const invalidSteps = [];

    this.getUploadEventInformationFormGroup().markAllAsTouched();
    this.getUploadEventTicketingFormGroup().markAllAsTouched();
    this.getUploadEventGenreTagsFormGroup().markAllAsTouched();
    this.getUploadEventBoostersFormGroup().markAllAsTouched();

    if (this.getUploadEventInformationFormGroup().invalid) {
      invalidSteps.push('eventInformation');
    }

    if (
      this.getUploadEventTicketingFormGroup().invalid &&
      !this.getUploadEventTicketingFormGroup().disabled
    ) {
      invalidSteps.push('ticketing');
    }

    if (this.getUploadEventGenreTagsFormGroup().invalid) {
      invalidSteps.push('genresTags');
    }

    if (this.getUploadEventBoostersFormGroup().invalid) {
      invalidSteps.push('booster');
    }

    return invalidSteps;
  }
}
